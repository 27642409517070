import {useRef} from 'react';

import {FAQ} from '@/components/common/faq/FAQ';
import {Section, SECTION_STYLES} from '@/components/common/section/Section';
import {Title} from '@/components/common/title/Title';
import {questions} from '@/components/page/vk-work-space/faq-section/questions';

import styles from './FaqSectoin.module.css';

type FaqSectionProps = {
	id?: string;
};

export function FaqSection({id}: FaqSectionProps) {
	const titleRef = useRef();

	return (
		<Section style={SECTION_STYLES.WITH_BREAKPOINTS} sectionId={id} className={styles.root}>
			<Title with1680={false} className={styles.title} ref={titleRef}>
				Популярные вопросы о&nbsp;платформе
			</Title>
			<FAQ
				questions={questions}
				questionClassName={styles.question}
				answerClassName={styles.answer}
				questionRootClassName={styles.questionRoot}
			/>
		</Section>
	);
}
