import {IButtonReadAll, ICase} from '@/components/page/vk-work-space/cases/interfaces';
import {LINKS} from '@/constants';

import LogoBigam from './assets/bigam.webp';
import LogoDrive from './assets/drive.webp';
import LogoIndever from './assets/indever.webp';
import LogoRosgosstrah from './assets/rosgosstrah.webp';
import LogoSamolet from './assets/samolet.webp';
import LogoSimbir from './assets/simbir.webp';
export const data: ICase[] = [
	{
		logo: LogoSamolet,
		description:
			'Как развернуть корпоративную почту для 10 000 сотрудников франчайзи без покупки серверов: кейс «Самолета»',
		link: LINKS.caseSamolet,
	},
	{
		logo: LogoDrive,
		description:
			'«В пятницу нас заблокировал Slack, а в понедельник мы уже работали в VK Teams»: кейс Ситидрайва',
		link: LINKS.caseSitidraiv,
	},
	{
		logo: LogoSimbir,
		description:
			'Как за один день переехать с Microsoft на новый сервис для звонков, подключить почту и перенести 1200 чатов в мессенджере с помощью робота: кейс SimbirSoft',
		link: LINKS.caseSimbirsoft,
	},
	{
		logo: LogoRosgosstrah,
		description:
			'Автоматизировали выдачу почтовых ящиков и перевели на бизнес-почту 30 тысяч работников: кейс «Росгосстраха» и VK WorkMail',
		link: LINKS.caseRosgosstrax,
	},
	{
		logo: LogoIndever,
		description:
			'Как федеральная сеть Indever заменила почту, облако и приложение для звонков на платформу VK WorkSpace',
		link: LINKS.caseIndever,
	},
	{
		logo: LogoBigam,
		description:
			'«Искали замену Microsoft и сравнивали сервисы по 57 параметрам. Выиграл VK WorkSpace»: кейс «Бигама»',
		link: LINKS.caseBigama,
	},
];

export const buttonReadAll: IButtonReadAll = {
	text: 'Все истории',
	link: LINKS.caseAll,
};
