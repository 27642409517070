import classNames from 'classnames';
import {ReactElement, VFC} from 'react';

import styles from './ReasonCards.module.css';

export type ItemProps = {
	id: number;
	title: string | ReactElement;
	text: string | ReactElement;
	img: VFC<React.SVGProps<SVGSVGElement>>;
};

type ReasonCardsProps = {
	list: ItemProps[];
	className?: string;
	classNameIcon?: string;
	classNameItem?: string;
};

export function ReasonCards({list, className, classNameIcon, classNameItem}: ReasonCardsProps) {
	return (
		<ul className={classNames(styles.list, className)}>
			{list.map((item) => {
				const Img = item.img;
				return (
					<li key={item.id} className={classNames(styles.item, classNameItem)}>
						<div className={classNames(styles.icon, classNameIcon)}>
							<Img className={styles.img} />
						</div>
						<div>
							{item.title}
							{item.text}
						</div>
					</li>
				);
			})}
		</ul>
	);
}
